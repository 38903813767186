import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ActionButton, DeleteButton } from "../../common/Buttons";
import { TableBase } from "../../common/Tables";
import { DetailsDialog, DialogSection } from "../../common/Dialog";
import { FormatDateMonospace, FormatList } from "../../common/Utils";
import GenerateAuthCodeDialog from "./GenerateAuthCodeDialog";
import UpdateDsDialog from "./UpdateDsDialog";
import CreateOrderDialog from "../../order/CreateOrderDialog";
import UpdateDomainLifecycleDialog from "../../common/UpdateDomainLifecycleDialog";
import ChangeAccountDialog from "../../common/ChangeAccountDialog";
import ReadOnlyForm from "../../common/ReadOnlyForm";

export default class DomainDetailsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whois: null,
      ds: null,
      whoisError: false,
      dsError: false,
      generateAuthCodeDialogShow: false,
      updateDsDialogShow: false,
      createOrderDialogOwnerTransferShow: false,
      createOrderDialogSwitchContactShow: false,
      createOrderDialogDelegateShow: false,
      updateLifecycleDialogShow: false,
      changeAccountDomainDialogShow: false,
      errorMessage: null,
      submit: null,
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.reload();
    }
  }

  reload = () => {
    this.reloadWhois();
    this.reloadDs();
  };

  reloadWhois = () => {
    this.setState({ whois: null, whoisError: false });

    if (this.props.domain?.links.whois) {
      this.props.session.backendGet(
        this.props.domain.links.whois,
        (response) => {
          this.setState({ whois: response.whois });
        },
        () => {
          this.setState({ whoisError: true });
        },
      );
    }
  };

  reloadDs = () => {
    this.setState({ ds: null, dsError: false });

    if (this.props.domain?.links.ds) {
      this.props.session.backendGet(
        this.props.domain.links.ds,
        (response) => {
          this.setState({ ds: response });
        },
        () => {
          this.setState({ dsError: true });
        },
      );
    }
  };

  getBasicInfo = () => {
    const domain = this.props.domain;

    const content = [
      {
        label: "Local Contact",
        value: domain.localContact,
        conditionalEnableDisable: true,
      },
      {
        label: "Private Whois",
        value: domain.privateWhois,
        conditionalEnableDisable: true,
      },
      {
        label: "Registry Lock",
        value: domain.registryLock,
        conditionalEnableDisable: true,
      },
      {
        label: "Expiry Date",
        value: FormatDateMonospace(domain.expires),
      },
      {
        label: "On Expiry",
        value: domain.onExpire,
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Organisation",
        value: domain.organisation,
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Comments",
        value: domain.comments,
      },
    ];

    return (
      <DialogSection title="Basic Info">
        <ReadOnlyForm content={content} />
      </DialogSection>
    );
  };

  getContacts = () => {
    return [
      <Row key="data">
        <Col xs={12}>
          {Object.keys(this.props.domain.contacts).map((key) => {
            const contact = this.props.domain.contacts[key];
            return this.getContact(key + " Contact", contact);
          })}
        </Col>
      </Row>,
    ];
  };

  getContact = (label, contact) => {
    let content = [
      {
        label: "Name",
        value: contact.name,
      },
      {
        label: "Organisation",
        value: contact.org,
      },
      {
        label: "Street",
        value: [contact.street1, contact.street2].filter((o) => o).join("\n"),
      },
      {
        label: "Postal Code",
        value: contact.postalCode,
      },
      {
        label: "City",
        value: contact.city,
      },
      {
        label: "Country",
        value: contact.countryCode,
      },
      {
        label: "Email",
        value: contact.email,
      },
      {
        label: "Voice",
        value: contact.voice,
      },
      {
        label: "Extensions",
        valueStyle: { paddingRright: "40px", minWidth: "300px" },
        value: Array.from(
          contact.extensions,
          (o) => o.type + ": " + o.value,
        ).join("\n"),
      },
    ];

    return (
      <DialogSection inline="true" title={label}>
        <ReadOnlyForm content={content} />
      </DialogSection>
    );
  };

  getNameservers = () => {
    const nameserverColumns = [
      {
        name: "name",
        style: { width: "200px" },
      },
      {
        label: "IPv4",
        style: { width: "180px" },
        contentFunction: (r) => FormatList(r.ipv4s),
      },
      {
        label: "IPv6",
        contentFunction: (r) => FormatList(r.ipv6s),
      },
    ];

    return (
      <DialogSection title="Nameservers">
        <TableBase
          columns={nameserverColumns}
          rows={this.props.domain.nameservers}
        />
      </DialogSection>
    );
  };

  getWhois = () => {
    let content = "Loading, please wait";
    if (this.state.whoisError) {
      content = "Failed fetching data. Please try again later.";
    } else if (this.state.whois !== null) {
      content = this.state.whois;
    }

    return (
      <DialogSection title="Whois">
        <pre>{content}</pre>
      </DialogSection>
    );
  };

  getDs = () => {
    let parts = [];

    let title = (
      <Row>
        <Col xs={12}>
          <div className="section-title">DNSSEC</div>
        </Col>
      </Row>
    );

    let loading = (
      <Row key="loading">
        <Col xs={12}>
          <pre>Loading, please wait</pre>
        </Col>
      </Row>
    );

    let error = (
      <Row key="error">
        <Col xs={12}>
          <pre>Failed fetching data. Please try again later.</pre>
        </Col>
      </Row>
    );

    parts.push(title);
    if (this.state.dsError) {
      parts.push(error);
    } else if (this.state.ds === null) {
      parts.push(loading);
    } else {
      parts.push(this.getDsStatus());
      parts.push(this.getDsFromSource("dns"));
      parts.push(this.getDsFromSource("epp"));
    }

    return parts;
  };

  getDsStatus = () => {
    return [
      <Row key="dsStatus">
        <Col xs={12}>
          <div className="sub-section-status">
            <b>Status: </b> {this.state.ds.description}
            <br />
            <br />
          </div>
        </Col>
      </Row>,
    ];
  };

  getDsFromSource = (source) => {
    const dsColumns = [
      {
        name: "keyTag",
        label: "Key Tag",
        style: { width: "100px" },
      },
      {
        name: "digest",
        label: "Digest",
      },
      {
        name: "alg",
        label: "Algorithm",
        style: { width: "150px" },
      },
      {
        name: "digestType",
        label: "Digest Type",
        style: { width: "140px" },
      },
    ];

    const keyColumns = [
      {
        name: source === "epp" ? "digest" : "pubKey",
        label: "Public Key",
      },
      {
        name: "alg",
        label: "Algorithm",
        style: { width: "150px" },
      },
    ];

    let title;
    let content;
    if (source === "dns") {
      title = "DS Data from Zone";
      content = (
        <TableBase
          columns={this.state.ds.publicKey ? keyColumns : dsColumns}
          rows={this.state.ds.zoneDsRecords}
        />
      );
    } else {
      title = "DS Data from Registry";
      content = (
        <TableBase
          columns={this.state.ds.publicKey ? keyColumns : dsColumns}
          rows={this.state.ds.parentDsRecords}
        />
      );
    }

    return <DialogSection title={title}>{content}</DialogSection>;
  };

  showGenerateAuthCodeDialog = () => {
    this.props.onClose();
    this.setState({
      generateAuthCodeDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideGenerateAuthCodeDialog = () => {
    this.props.onClose();
    this.setState({
      generateAuthCodeDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  showUpdateDsDialog = () => {
    this.props.onClose();
    this.setState({
      updateDsDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideUpdateDsDialog = () => {
    this.props.onClose();
    this.setState({
      updateDsDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  showUpdateLifecycleDialog = () => {
    this.props.onClose();
    this.setState({
      updateLifecycleDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideUpdateLifecycleDialog = () => {
    this.props.onClose();
    this.setState({
      updateLifecycleDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  showChangeAccountDialog = () => {
    this.props.onClose();
    this.setState({
      changeAccountDomainDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideChangeAccountDialog = () => {
    this.props.onClose();
    this.setState({
      changeAccountDomainDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  updateLifecycle = (resource) => {
    const errorFun = (msg) => {
      this.setState({ errorMessage: msg, submit: false });
    };
    const callback = () => {
      this.hideUpdateLifecycleDialog();
      this.setState({ errorMessage: null });
      let text;
      if (
        resource.relatedSubscriptionIds &&
        resource.relatedSubscriptionIds.length > 0
      ) {
        text = "Domain and related services are";
      } else {
        text = "Domain is";
      }
      this.props.window.showSuccessFunc(text + " updated successfully!");
    };
    this.setState({ submit: true });
    let url = this.props.domain.links.lifecycle;
    this.props.session.backendPut(url, resource, callback, errorFun);
    this.props.onClose();
  };

  updateDs = (action) => {
    if (this.props.domain.registryLock) {
      this.setState({
        errorMessage:
          "This domain has Registry Lock. Please contact us for further assistance",
        submit: false,
      });
      return;
    }

    if (action === null) {
      this.setState({
        errorMessage: "Please select one action",
        submit: false,
      });
      return;
    }

    const errorFun = (msg) => {
      this.setState({ errorMessage: msg, submit: false });
    };
    const callback = () => {
      this.hideUpdateDsDialog();
      this.setState({ errorMessage: null });
      const message = "DS data updated successfully";
      this.props.window.showSuccessFunc(message);
    };

    let url = "";
    if (this.props.domain?.links.syncDs && this.props.domain?.links.clearDs) {
      if (action === "sync") {
        url = this.props.domain.links.syncDs;
      } else {
        url = this.props.domain.links.clearDs;
      }
      this.setState({ submit: true });
      this.props.session.backendPost(url, {}, callback, errorFun);
    }
  };

  getBody = () => {
    let parts = [];

    if (this.props.domain) {
      parts.push(this.getBasicInfo());
      parts.push(this.getContacts());
      parts.push(this.getNameservers());
      parts.push(this.getDs());
      parts.push(this.getWhois());
    }
    return (
      <Container key="parts" fluid={true}>
        {parts}
      </Container>
    );
  };

  getDisabledLifecycleTooltip = (
    action,
    renew,
    hasRoleOrderEditDomainEdit,
    lifecycleUpdatable,
  ) => {
    if (!hasRoleOrderEditDomainEdit) {
      return (
        "Your user account does not have privileges to " +
        action +
        " the domain on expiration date, contact your client manager to activate this functionality"
      );
    } else if (!lifecycleUpdatable) {
      if (renew) {
        return "The cancellation process has started. If you want to renew the domain, please contact your client manager as soon as possible. There is no guarantee that we can still renew the domain.";
      } else {
        return "The renewal process has started. You will be able to cancel the domain on expiration date after the initiated renewal has been completed. ";
      }
    }
    return "";
  };

  getDisabledTransferTooltip = (hasRoleOrderEditDomainEdit, domain) => {
    if (hasRoleOrderEditDomainEdit) {
      if (domain && !domain.authCodeGenerationAvailible) {
        return "Transfer Out is not possible with this domain, please contact your client manager for help with the operation";
      } else if (domain && domain.localContact) {
        return "Transfer Out is not possible with this domain as it has Local Contact enabled, please contact your client manager for help with the operation";
      } else {
        return "";
      }
    }
    return "Your user account does not have privileges to transfer out this domain, contact your client manager to activate this functionality";
  };

  getDisabledDsTooltip = (hasRoleEdit, dsSyncable) => {
    if (!hasRoleEdit) {
      return "Your user account does not have privileges to Update DS Data, contact your client manager to activate this functionality";
    } else if (!dsSyncable) {
      return "You can't update DS data as either the TLD doesn't support automatic DS data update (but you can contact us to do it) or a zone object exists (implying only Abions managed DNSSEC solution is available)";
    }
  };

  getActionButtons = () => {
    let parts = [];
    let hasRoleEdit =
      this.props.session.hasRole("ORDER_CREATE") &&
      this.props.session.hasRole("DOMAIN_ADMIN_EDIT");
    let hasRoleOrderEditDomainEdit =
      this.props.session.hasRole("ORDER_EDIT") &&
      this.props.session.hasRole("DOMAIN_ADMIN_EDIT");
    let dsSyncable = this.state.ds?.syncable;

    parts.push(
      <ActionButton
        key="Nameservers"
        onClick={() => {
          this.props.onClose();
          this.setState({ createOrderDialogDelegateShow: true });
        }}
        text="Update Nameservers"
        disabled={!hasRoleEdit}
        tooltip="Open the order dialog and fill in details to order a domain delegation, it allows you to change nameservers used by the domain."
        disabledTooltip="Your user account does not have privileges to order domain delegations, contact your client manager to activate this functionality"
      />,
    );

    parts.push(
      <ActionButton
        key="DsData"
        text={"Update DS Data"}
        disabled={!hasRoleEdit || !dsSyncable}
        disabledTooltip={this.getDisabledDsTooltip(hasRoleEdit, dsSyncable)}
        onClick={() => this.showUpdateDsDialog()}
      />,
    );

    parts.push(
      <ActionButton
        key="OwnerTransfer"
        onClick={() => {
          this.props.onClose();
          this.setState({ createOrderDialogOwnerTransferShow: true });
        }}
        text="Owner Transfer"
        disabled={!hasRoleEdit}
        tooltip="Open the order dialog and fill in details to order a domain owner transfer, it allows you to change all contact information on a domain, including owner related contact information."
        disabledTooltip="Your user account does not have privileges to order owner transfers, contact your client manager to activate this functionality"
      />,
    );

    parts.push(
      <ActionButton
        key="Change Contacts"
        onClick={() => {
          this.props.onClose();
          this.setState({ createOrderDialogSwitchContactShow: true });
        }}
        text="Change Contacts"
        disabled={!hasRoleEdit}
        tooltip="Open the order dialog and fill in details to order a domain contact change, it allows you to change the contact information on the domain, that is not owner related contact information."
        disabledTooltip="Your user account does not have privileges to order contact changes, contact your client manager to activate this functionality"
      />,
    );

    if (this.props.domain) {
      parts.push(
        <ActionButton
          text={"Change Account"}
          onClick={() => this.showChangeAccountDialog()}
          disabled={!hasRoleOrderEditDomainEdit}
          disabledTooltip={
            "Your user account does not have privileges to change account on the domain, contact your client manager to activate this functionality"
          }
        />,
      );

      let lifecycleUpdatable = this.props.domain.dateLifecycleUpdatable;
      let renew = this.props.domain.onExpire !== "Renew";
      let action = renew ? "Renew" : "Cancel";
      let disabledLifecycleDomainTooltip = this.getDisabledLifecycleTooltip(
        action,
        renew,
        hasRoleOrderEditDomainEdit,
        lifecycleUpdatable,
      );
      let className = renew ? "" : "delete";

      parts.push(
        <ActionButton
          text={renew ? "Renew on Expiry" : "Cancel on Expiry"}
          tooltip={action + " the domain on expiration date"}
          className={className}
          disabled={!hasRoleOrderEditDomainEdit || !lifecycleUpdatable}
          disabledTooltip={disabledLifecycleDomainTooltip}
          onClick={() => this.showUpdateLifecycleDialog()}
        />,
      );
    }

    parts.push(
      <DeleteButton
        key="Transfer Out"
        text={"Transfer Out"}
        tooltip={
          "Will generate a Authorization Code, which is used to transfer the domain."
        }
        disabled={
          !hasRoleOrderEditDomainEdit ||
          !this.props.domain ||
          !this.props.domain.authCodeGenerationAvailible ||
          this.props.domain.localContact
        }
        disabledTooltip={this.getDisabledTransferTooltip(
          hasRoleOrderEditDomainEdit,
          this.props.domain,
        )}
        onClick={() => this.showGenerateAuthCodeDialog()}
      />,
    );
    return parts;
  };

  render() {
    let title = "";
    let parameters = {};

    if (this.props.domain) {
      title = this.props.domain.name;
      parameters.domainNames = this.props.domain.name;
    }

    return (
      <div id="parts">
        <DetailsDialog
          show={this.props.show}
          title={title}
          actions={this.getActionButtons()}
          body={this.getBody()}
          height="lg"
          width="xl"
          onClose={() => this.props.onClose()}
        />
        <UpdateDsDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.updateDsDialogShow}
          title={title}
          errorMessage={this.state.errorMessage}
          submit={this.state.submit}
          onSave={this.updateDs}
          onCancel={this.hideUpdateDsDialog}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.createOrderDialogOwnerTransferShow}
          onClose={() =>
            this.setState({ createOrderDialogOwnerTransferShow: false })
          }
          step="OPERATION"
          operation="DOMAIN-OWNER_TRANSFER"
          parameters={parameters}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.createOrderDialogSwitchContactShow}
          onClose={() =>
            this.setState({ createOrderDialogSwitchContactShow: false })
          }
          step="OPERATION"
          operation="DOMAIN-SWITCH_CONTACT"
          parameters={parameters}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.createOrderDialogDelegateShow}
          onClose={() =>
            this.setState({ createOrderDialogDelegateShow: false })
          }
          step="OPERATION"
          operation="DOMAIN-DELEGATE_DOMAIN"
          parameters={parameters}
        />
        <UpdateDomainLifecycleDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.updateLifecycleDialogShow}
          errorMessage={this.state.errorMessage}
          submit={this.state.submit}
          domain={this.props.domain}
          onConfirm={this.updateLifecycle}
          onClose={this.hideUpdateLifecycleDialog}
        />
        <GenerateAuthCodeDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.generateAuthCodeDialogShow}
          errorMessage={this.state.errorMessage}
          submit={this.state.submit}
          domain={this.props.domain}
          onClose={this.hideGenerateAuthCodeDialog}
        />
        {this.state.changeAccountDomainDialogShow && (
          <ChangeAccountDialog
            session={this.props.session}
            window={this.props.window}
            show={this.state.changeAccountDomainDialogShow}
            errorMessage={this.state.errorMessage}
            submit={this.state.submit}
            domainSubscriptionsUrl={this.props.domain.links.subscriptions}
            changeAccountUrl={this.props.domain.links.changeAccount}
            onClose={this.hideChangeAccountDialog}
          />
        )}
      </div>
    );
  }
}
