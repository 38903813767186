import styled, { keyframes } from "styled-components";
import { Col } from "react-bootstrap";
import Colors from "../common/theme/Colors";
import { BorderColor } from "../common/theme/Theme";
import { borderRadius, darken, rgba } from "polished";
import { pulse } from "react-animations";
import { Chart } from "react-google-charts";

export const pulseAnimation = keyframes`${pulse}`;

export const WidgetPanel = styled(Col)`
  position: relative;

  &.centered {
    text-align: center;
  }

  &.no-margin {
    margin: 0;
  }
`;

export const WidgetInner = styled.div`
  background: ${Colors.white};
  border: 1px solid ${BorderColor};
  overflow: hidden;

  &.blue {
    background: ${Colors.white};
    color: ${Colors.secondary};
  }
  &.green {
    background: ${Colors.white};
    color: ${Colors.primary};
  }
  &.grey {
    background: ${Colors.white};
    color: ${Colors.tertiary};
  }

  &.no-shadow {
    box-shadow: none;
  }
  &li {
    border-bottom: 1px solid ${BorderColor};
    ${borderRadius("top", "0px")};
    ${borderRadius("bottom", "0px")};

    &:last-child {
      border: none;
    }
  }
`;

export const Curtain = styled.div`
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  background: ${rgba(Colors.tertiary, 0.75)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  text-align: center;
  ${borderRadius("top", "4px")};
  ${borderRadius("bottom", "4px")};

  visibility: hidden;
  opacity: 0;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  svg {
    position: relative;
    top: 250px;
    display: block;
    margin: 0 auto;
    color: ${Colors.white};
  }
`;

export const WidgetTitle = styled.div`
  height: 75px;
  text-transform: uppercase;
  border-bottom: 1px solid ${BorderColor};
  padding-left: 35px;
  font-size: 18px;
  font-weight: 900;

  .controller {
    padding: 7px 0 0 0;
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
    margin-top: 15px;
  }
  .title {
    padding: 27px 35px 0 0;
  }
  .backbtn {
    padding: 20px 35px 0 0;
    text-align: right;
  }
`;

export const WidgetContent = styled.div`
  min-height: ${(props) => props.minheight};
  max-height: ${(props) => props.maxheight};
  overflow-y: auto;
  overflow-x: hidden;

  &.dark {
    background: ${Colors.tertiary};
  }
  &.centered {
    text-align: center;
  }
  &.animated-pulse {
    &:hover {
      cursor: pointer;
      animation: 1s ${pulseAnimation} infinite;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .legend {
    position: absolute;
    left: 20px;
    bottom: 15px;
    vertical-align: middle;
  }

  .legend .image {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid ${Colors.tertiary};
  }

  .generic-box {
    padding-left: 10px;
    padding-top: 10px;
    position: absolute;
    right: 15px;
    bottom: 18px;
    background: white;
  }

  .generic {
    background-color: #f2f2f2;
    padding: 3px;
    border-radius: 3px;
    border: 1px solid #dadada;
    width: 123px;
  }

  .legend .two {
    background-color: ${darken(0.2, Colors.primary)};
  }

  .legend .one {
    background-color: ${Colors.primary};
  }

  .legend .zero {
    background-color: ${Colors.bodyBackground};
  }

  .legend .text {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    bottom: 5px;
  }
`;

export const FilterStyle = styled.span`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }

  .parent {
    padding-left: 20px;
  }

  .parent.not-selected {
    padding-left: 32px;
  }

  .child {
    padding-left: 40px;
  }

  .child.not-selected {
    padding-left: 52px;
  }

  .parent::before,
  .child::before {
    content: "\\2043\\202F\\202F";
  }

  .dropdown-menu {
    max-height: 400px;
    max-width: 450px;
    overflow: auto;
  }
`;

export const GhostCol = styled(Col)`
  && {
    position: relative;
    display: block;
    overflow: hidden;
  }
`;

export const Frontside = styled.div`
  position: relative;
`;

export const StyledChart = styled(Chart)`
  .google-visualization-tooltip {
    font-size: 13px !important;
    padding: 5px;
  }
`;
