import fileDownload from "js-file-download";
import React, { Component } from "react";
import styled from "styled-components";
import {
  BlockingActionButton,
  DropdownButton,
  MainActionButton,
  TableButton,
} from "../common/Buttons";
import { TableBackend, TableFilter } from "../common/Tables";
import { FormatOrganisation } from "../common/Utils";
import { Dropdown } from "react-bootstrap";
import { UpdateCommentsIcon } from "../common/theme/Icons";
import UpdateCommentsDialog from "../common/UpdateCommentsDialog";
import OrganisationFilter from "../common/OrganisationFilter";
import IpRightDetailsDialog from "../common/IpRightDetailsDialog";
import {
  FormatClass,
  FormatCountry,
  FormatDate,
  FormatNumber,
} from "../common/IpRightUtils";
import { IpRightLogo } from "../common/IpRightLogo";
import CreateOrderDialog from "../order/CreateOrderDialog";


const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;

class IpRightsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        accountIds: "",
        freetext: "",
        type: "ALL",
        renewalDate: "ALL",
        status: "ALL",
        reloadFlag: false,
      },
      ipRight: null,
      showDetailsDialog: false,
      showUpdateCommentsDialog: false,
      showCreateDialog: false,
      comments: null,
      commentsUrl: null,
      submit: false,
      errorMessage: null,
      exported: 0,
    };
  }

  getUrl = (sorting, expanding, parameters) => {
    let url =
      this.props.session.getModules().portfolio.links.portfolio + "/ipRights";
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&legalTypeFilter=" + parameters.type;
    url += "&renewalDateFilter=" + parameters.renewalDate;
    url += "&accountIdFilter=" + parameters.accountIds;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  getTypeFilterLabel = (type) => {
    if (type === "TRADEMARK") {
      return "Trademarks";
    } else if (type === "DESIGN") {
      return "Designs";
    } else if (type === "PATENT") {
      return "Patents";
    } else if (type === "COPYRIGHT") {
      return "Copyrights";
    } else {
      return "All Types";
    }
  };

  getRenewalDateFilterLabel = (dateFilter) => {
    if (dateFilter === "RENEWAL_WITHIN_18_MONTHS") {
      return "Renewal within 18 Months";
    } else if (dateFilter === "RENEWAL_WITHIN_12_MONTHS") {
      return "Renewal within 12 Months";
    } else if (dateFilter === "RENEWAL_WITHIN_6_MONTHS") {
      return "Renewal within 6 Months";
    } else {
      return "All Renewal Dates";
    }
  };

  onEditComments = (url, comments) => {
    this.setState({
      showUpdateCommentsDialog: true,
      commentsUrl: url,
      comments: comments,
    });
  };

  onUpdateComments = (newComments) => {
    const message = "Comments updated successfully";
    if (
      newComments === null ||
      (newComments.trim() === "" && this.state.comments === null) ||
      this.state.comments === newComments.trim()
    ) {
      this.props.window.showSuccessFunc(message);
      this.closeDialogs();
    } else {
      this.setState({ submit: true });
      const errorFun = (msg) => {
        this.setState({ submit: false, errorMessage: msg });
      };
      this.props.session.backendPut(
        this.state.commentsUrl,
        {
          comments: newComments.trim(),
        },
        () => {
          this.props.window.showSuccessFunc(message);
          this.closeUpdateCommentsDialog();
          this.reloadPage();
        },
        errorFun,
      );
    }
  };

  reloadPage = () => {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  };

  openDetailsDialog = (ipRight) => {
    this.setState({
      ipRight: ipRight,
      showDetailsDialog: true,
    });
  };

  closeDetailsDialog = () => {
    this.setState({
      showDetailsDialog: false,
    });
  };

  onChangeAccount = () => {
    this.reloadPage();
  };

  closeUpdateCommentsDialog = () => {
    this.setState({
      commentsUrl: null,
      comments: null,
      showUpdateCommentsDialog: false,
      submit: false,
      errorMessage: null,
    });
  };

  exportData = () => {
    let url = this.props.session.getModules().portfolio.links.exportIpRights;
    url += "?legalTypeFilter=" + this.state.parameters.type;
    url += "&renewalDateFilter=" + this.state.parameters.renewalDate;
    url += "&accountIdFilter=" + this.state.parameters.accountIds;
    url +=
      "&freetextFilter=" + encodeURIComponent(this.state.parameters.freetext);

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "IP Rights.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  getLogo = (r) => {
    if (!r.links.image) {
      return;
    }
    const url = r.links.image;
    return <IpRightLogo session={this.props.session} url={url} />;
  };

  render() {
    let actions = [];

    actions.push(
      <MainActionButton
        key="register-trademark"
        onClick={() => this.setState({ showCreateDialog: true })}
        text="Register Trademark"
        icon="plus"
        prefix="fas"
        tooltip="Open the order dialog and fill in details to order a new trademark registration."
        disabled={!this.props.session.hasRole("ORDER_CREATE")}
        disabledTooltip="Your user account does not have privileges to order trademarks, contact your client manager to activate this functionality."
        invisible={this.props.session.hasRole("SYS_ADMIN")}
      />,
    );

    actions.push(
      <BlockingActionButton
        key="export"
        onClick={this.exportData}
        text={"Export"}
        icon={"cloud-download"}
        prefix={"fas"}
        tooltip="Exports the IP Rights to an Excel file. Note that only rows matching current filter are exported."
        reload={this.state.exported}
      />,
    );

    const filters = [
      <OrganisationFilter
        key="organisation-filter"
        session={this.props.session}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              accountIds: o,
            }),
          })
        }
      />,
      <DropdownButton
        key="type-filter"
        title={this.getTypeFilterLabel(this.state.parameters.type)}
        style={{ verticalAlign: "top" }}
      >
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                type: "ALL",
              }),
            })
          }
        >
          {this.getTypeFilterLabel("ALL")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                type: "TRADEMARK",
              }),
            })
          }
        >
          {this.getTypeFilterLabel("TRADEMARK")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                type: "DESIGN",
              }),
            })
          }
        >
          {this.getTypeFilterLabel("DESIGN")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                type: "PATENT",
              }),
            })
          }
        >
          {this.getTypeFilterLabel("PATENT")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                type: "COPYRIGHT",
              }),
            })
          }
        >
          {this.getTypeFilterLabel("COPYRIGHT")}
        </Dropdown.Item>
      </DropdownButton>,
      <DropdownButton
        key="renewal-filter"
        title={this.getRenewalDateFilterLabel(
          this.state.parameters.renewalDate,
        )}
        style={{ verticalAlign: "top" }}
      >
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                renewalDate: "ALL",
              }),
            })
          }
        >
          {this.getRenewalDateFilterLabel("ALL")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                renewalDate: "RENEWAL_WITHIN_18_MONTHS",
              }),
            })
          }
        >
          {this.getRenewalDateFilterLabel("RENEWAL_WITHIN_18_MONTHS")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                renewalDate: "RENEWAL_WITHIN_12_MONTHS",
              }),
            })
          }
        >
          {this.getRenewalDateFilterLabel("RENEWAL_WITHIN_12_MONTHS")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                renewalDate: "RENEWAL_WITHIN_6_MONTHS",
              }),
            })
          }
        >
          {this.getRenewalDateFilterLabel("RENEWAL_WITHIN_6_MONTHS")}
        </Dropdown.Item>
      </DropdownButton>,
      <TableFilter
        key="table-filter"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    ];

    const columns = [
      {
        label: "Description",
        name: "catchWord",
        sortable: true,
      },
      {
        label: "Logo",
        name: "logo",
        sortable: false,
        contentFunction: (r) => this.getLogo(r),
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: true,
        tooltip:
          "The Abion account where the IP Right belongs to. Note that it is different from the trademark applicant.",
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationPrefix,
          ),
      },
      {
        label: "Applicant",
        name: "applicantName",
        sortable: true,
      },
      {
        label: "Appl/Reg Number",
        name: "number",
        sortable: true,
        contentFunction: (r) => FormatNumber(r),
        multiSort: [{
                   label: "Appl Number",
                   name: "applicationNumber"
                },
                {
                  label: "Reg Number",
                  name: "registrationNumber" 
                }]
      },
      {
        label: "Appl/Reg/Renew Date",
        name: "date",
        sortable: true,
        contentFunction: (r) => FormatDate(r),
        multiSort: [{
           label: "Appl Date",
           name: "applicationDate"
        },
        {
          label: "Reg Date",
          name: "registrationDate" 
        },
        {
          label: "Renew Date",
          name: "renewDate" 
        },
]
        
      },
      {
        label: "Class",
        name: "classes",
        sortable: true,
        contentFunction: (r) => FormatClass(r),
      },
      {
        label: "Country",
        name: "countries",
        sortable: true,
        contentFunction: (r) => FormatCountry(r),
      },
      {
        label: "Comments",
        name: "comments",
        contentFunction: (r) => (
          <div style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
            <table>
              <tbody>
                <tr>
                  <td width={"150px"}>{r.comments}</td>
                  <td style={{ verticalAlign: "top" }}>
                    <UpdateCommentsIcon
                      onClick={() =>
                        this.onEditComments(r.links.comments, r.comments)
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
      {
        label: "",
        name: "action",
        style: { width: "150px" },
        contentFunction: (r) => (
          <TableButton
            onClick={() => this.openDetailsDialog(r)}
            icon="eye"
            prefix={"fas"}
            text="Details"
            className={"secondary"}
          />
        ),
      },
    ];

    return (
      <PageStyle>
        <TableBackend
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          filters={filters}
          columns={columns}
          sorting="catchWord"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
        />
        <IpRightDetailsDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.showDetailsDialog}
          ipRight={this.state.ipRight}
          onClose={this.closeDetailsDialog}
          onChangeAccount={this.onChangeAccount}
        />
        <UpdateCommentsDialog
          session={this.props.session}
          window={this.props.window}
          comments={this.state.comments}
          show={this.state.showUpdateCommentsDialog}
          onCancel={this.closeUpdateCommentsDialog}
          onSave={this.onUpdateComments}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showCreateDialog}
          onClose={() => this.setState({ showCreateDialog: false })}
          step="OPERATION"
          operation="MANUAL-TRADEMARK_REGISTRATION"
        />
      </PageStyle>
    );
  }
}

export default IpRightsTab;
