import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Icon from "./theme/Icons";

export const FormatFeatures = (domain) => {
  const features = [];
  features.push(
    <Feature
      iconName={"address-card"}
      text={getText("Local Contact", domain.localContact)}
      enabled={domain.localContact}
    />,
  );
  features.push(
    <Feature
      iconName={"user-secret"}
      text={getText("Private Whois", domain.privateWhois)}
      enabled={domain.privateWhois}
    />,
  );
  features.push(
    <Feature
      iconName={"lock"}
      text={getText("Registry Lock", domain.registryLock)}
      enabled={domain.registryLock}
    />,
  );

  return <div style={{ whiteSpace: "nowrap" }}>{features}</div>;
};

const getText = (text, enabled) => {
  if (enabled) {
    return text + " - " + "Enabled";
  } else {
    return text + " - " + "Disabled";
  }
};

class Feature extends Component {
  render() {
    const popover = (
      <Tooltip
        id="tooltip"
        style={{ position: "fixed", pointerEvents: "none" }}
      >
        {this.props.text}
      </Tooltip>
    );

    const color = this.props.enabled ? "success" : "inactive";

    return (
      <OverlayTrigger placement={"bottom"} overlay={popover}>
        <Icon
          prefix={"far"}
          name={this.props.iconName}
          className={"menu-icon"}
          color={color}
          paddingright={8}
        />
      </OverlayTrigger>
    );
  }
}
